const SET_LANG = 'setLang';
const SET_THEME = 'setTheme';
const SET_USER = 'setUser';
const SET_SIGOUT = 'signOut';
const SET_SIGNIN = 'signin';
const CHANGE_META_INFO = 'changeMetaInfo';

export {
  SET_LANG,
  SET_THEME,
  SET_USER,
  SET_SIGOUT,
  SET_SIGNIN,
  CHANGE_META_INFO
}
